























































































































































































import {
  Component, Vue, Prop, Watch, Inject, Ref,
} from 'vue-property-decorator';
import {
  Policy, Library, Rule, PolicyUpdateModel,
} from '@/models/library-maintenance.d';
import MetadataEditor from '@/components/library-maintenance/metadata-editor.vue';
import EcTextField from 'common-components/src/components/form/ec-text-field.vue';
import EcCheckbox from 'common-components/src/components/form/ec-checkbox.vue';
import EcSelect from 'common-components/src/components/form/ec-select.vue';
import EcPopUpSelect from '@/components/form/hd-popup-select.vue';
import Card from '@/components/material/Card.vue';
import LibraryLookup from '@/components/library-maintenance/library-lookup.vue';
import RuleLookup from '@/components/library-maintenance/rule-lookup.vue';
import * as validation from '@/models/validation';
import ServiceHookService from '@/services/service-hook-service';
import { ApiError } from '@/models/hal.d';
import { TableOptions, FieldList, Field } from '@/models/form';

@Component({
  components: {
    MetadataEditor,
    EcTextField,
    EcCheckbox,
    EcSelect,
    EcPopUpSelect,
    Card,
    LibraryLookup,
    RuleLookup,
  },
})
export default class PolicyEditor extends Vue {
  @Inject() ServiceHookService!: ServiceHookService;

  @Prop() value!: Policy

  @Prop({ default: () => ([]) }) apiErrors!: ApiError[];

  newMetadata = {};

  newMetadataEditMode = false;

  metadataEditModes: boolean[] = Vue.observable([]);

  serviceHooks: any[] = [];

  tableOptions: TableOptions = {
    selectable: true,
  }

  fieldList: FieldList = new FieldList({
    name: new Field(
      '',
      'Name',
      [
        validation.required(),
        validation.maxLength(50),
      ],
    ),
    description: new Field(
      '',
      'Description',
      [
        validation.maxLength(200),
      ],
    ),
    'run-by-default': new Field(false, 'Run By Default'),
    'run-always': new Field(false, 'Run Always'),
    'service-hooks': new Field([], 'Service Hooks'),
    'include-libraries': new Field([], 'Included Libraries'),
    'exclude-libraries': new Field([], 'Excluded Libraries'),
    'include-rules': new Field(
      [],
      'Included Rules',
      [
        validation.noDuplicates(() => this.fieldList.fields['exclude-rules']),
      ],
    ),
    'exclude-rules': new Field(
      [],
      'Excluded Rules',
      [
        validation.noDuplicates(() => this.fieldList.fields['include-rules']),
      ],
    ),
    metadata: new Field([], 'Metadata', [], [], 'filter.metadata'),
  });

  get fields() { return this.fieldList.fields; }

  originalPolicy!: Policy;

  includeLibrariesInit: Library[] = [];

  excludeLibrariesInit: Library[] = [];

  includeRulesInit: Rule[] = [];

  excludeRulesInit: Rule[] = [];

  get referencedLibraries() { return [this.fields['include-libraries'], this.fields['exclude-libraries']]; }

  @Watch('referencedLibraries', { deep: true })
  onLibrariesChanged() {
    this.disableLibraryInclude = this.fields['exclude-libraries'].value.length > 0;
    this.disableLibraryExclude = this.fields['include-libraries'].value.length > 0;
  }

  disableLibraryInclude = false;

  disableLibraryExclude = false;

  get policy() { return this.value; }

  get metadata() {
    const metadata: any[] = this.fields.metadata.value;
    const editModes = this.metadataEditModes;

    return metadata.map((x, index) => ({
      get value() {
        return { ...x };
      },
      set value(val: any) {
        metadata.splice(index, 1, val);
      },

      get editMode() {
        return editModes[index];
      },
      set editMode(val: boolean) {
        editModes.splice(index, 1, val);
      },
    }));
  }

  @Watch('newMetadata')
  addNewMetadata() {
    if (Object.keys(this.newMetadata).length === 0) return;

    const metadata = this.fields.metadata.value;

    this.metadataEditModes.push(true);
    metadata.push(this.newMetadata);

    this.newMetadataEditMode = false;
    this.newMetadata = {};
  }

  deleteMetadata(index: number) {
    const metadata: any[] = this.fields.metadata.value;
    metadata.splice(index, 1);
  }

  mounted() {
    this.$nextTick(async () => {
      const result = await this.ServiceHookService.listServiceHooks();
      const mapped = result._embedded['service-hooks'].map(({ id, name }) => ({ text: name, value: id }));
      this.serviceHooks = mapped;
    });
  }

  @Watch('value', { immediate: true })
  onPolicyChanged() {
    this.reset();
  }

  @Watch('apiErrors')
  onErrorsChanged() {
    this.fieldList.addAllErrors(this.apiErrors);
  }

  @Ref() form!: HTMLFormElement

  fetch(
    callback: (library: PolicyUpdateModel) => void,
    validationCallback: () => void,
  ) {
    const valid = this.form.validate();
    if (!valid) {
      validationCallback();
      return;
    }

    const updatedPolicy: PolicyUpdateModel = {
      name: this.fields.name.value,
      description: this.fields.description.value,
      'run-by-default': this.fields['run-by-default'].value,
      'run-always': this.fields['run-always'].value,
      'service-hooks': this.filterServiceHooks(this.fields['service-hooks'].value),
      filter: {
        'include-libraries': this.fields['include-libraries'].value,
        'exclude-libraries': this.fields['exclude-libraries'].value,
        'include-rules': this.fields['include-rules'].value,
        'exclude-rules': this.fields['exclude-rules'].value,
        metadata: this.fields.metadata.value,
      },
    };
    callback(updatedPolicy);
  }

  filterServiceHooks(serviceHookFields: any[]){
    if (serviceHookFields){
      return this.removeErroneousServiceHooks(serviceHookFields);
    }
    else {
      return [] as string[];
    }
  }

  removeErroneousServiceHooks(serviceHookFields: any[]){
    return serviceHookFields.filter(x => this.serviceHooks.filter(c => c.value == x).length > 0)
  }

  reset() {
    const clone = JSON.parse(JSON.stringify(this.value));

    this.fields.name.value = clone.name;
    this.fields.description.value = clone.description;
    this.fields['run-by-default'].value = clone['run-by-default'];
    this.fields['run-always'].value = clone['run-always'];
    this.fields['service-hooks'].value = clone['service-hooks'];
    this.fields.metadata.value = Vue.observable([]);

    if (clone.filter !== undefined) {
      this.fields['include-libraries'].value = clone.filter['include-libraries'];
      this.fields['exclude-libraries'].value = clone.filter['exclude-libraries'];
      this.fields['include-rules'].value = clone.filter['include-rules'];
      this.fields['exclude-rules'].value = clone.filter['exclude-rules'];

      this.fields.metadata.value = Vue.observable(clone.filter.metadata);

      const embedded = clone._embedded as any;
      this.includeLibrariesInit = embedded['included-libraries'];
      this.excludeLibrariesInit = embedded['excluded-libraries'];
      this.includeRulesInit = embedded['included-rules'];
      this.excludeRulesInit = embedded['excluded-rules'];

      if (this.$refs && this.$refs.includeLibraries) {
        (this.$refs.includeLibraries as any).reset();
        (this.$refs.excludeLibraries as any).reset();
        (this.$refs.includeRules as any).reset();
        (this.$refs.excludeRules as any).reset();
      }
    } else {
      this.fields['exclude-libraries'].value = [];
      this.fields['include-libraries'].value = [];
      this.fields['exclude-rules'].value = [];
      this.fields['include-rules'].value = [];
    }
  }
}
