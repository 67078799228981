var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"dense":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('ec-text-field',{attrs:{"field":_vm.fields.name}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('ec-text-field',{attrs:{"field":_vm.fields.description}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('ec-checkbox',{attrs:{"color":"darkgrey","field":_vm.fields['run-by-default']}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('ec-select',{attrs:{"multiple":"","field":_vm.fields['service-hooks'],"items":this.serviceHooks}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('ec-checkbox',{attrs:{"color":"darkgrey","field":_vm.fields['run-always']}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('ec-pop-up-select',{ref:"includeLibraries",attrs:{"field":_vm.fields['include-libraries'],"item-text":"name","item-value":"id","initial-items":_vm.includeLibrariesInit,"dialog-text":"Add/Remove Libraries for Inclusion","disabled":_vm.disableLibraryInclude,"data-cy":"select-includedLibraries"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selected = ref.selected;
var items = ref.items;
var close = ref.close;
var selectAll = ref.selectAll;
return [_c('card',{attrs:{"title":"Include Libraries"}},[_c('template',{slot:"toolbar-actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('library-lookup',{attrs:{"value":items},on:{"toggle-select-all":function($event){return selectAll( $event.items, $event.value )},"item-selected":function($event){return selected( $event.item, $event.value )}}})],2)]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('ec-pop-up-select',{ref:"excludeLibraries",attrs:{"field":_vm.fields['exclude-libraries'],"item-text":"name","item-value":"id","initial-items":_vm.excludeLibrariesInit,"dialog-text":"Add/Remove Libraries for Exclusion","disabled":_vm.disableLibraryExclude,"data-cy":"select-excludedLibraries"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selected = ref.selected;
var items = ref.items;
var close = ref.close;
var selectAll = ref.selectAll;
return [_c('card',{attrs:{"title":"Exclude Libraries"}},[_c('template',{slot:"toolbar-actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('library-lookup',{attrs:{"value":items},on:{"toggle-select-all":function($event){return selectAll( $event.items, $event.value )},"item-selected":function($event){return selected( $event.item, $event.value )}}})],2)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('ec-pop-up-select',{ref:"includeRules",attrs:{"field":_vm.fields['include-rules'],"item-text":"name","item-value":"id","initial-items":_vm.includeRulesInit,"dialog-text":"Add/Remove Rules for Inclusion","data-cy":"select-includedRules"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selected = ref.selected;
var items = ref.items;
var close = ref.close;
var selectAll = ref.selectAll;
return [_c('card',{attrs:{"title":"Include Rules"}},[_c('template',{slot:"toolbar-actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('rule-lookup',{attrs:{"value":items},on:{"toggle-select-all":function($event){return selectAll( $event.items, $event.value )},"item-selected":function($event){return selected( $event.item, $event.value )}}})],2)]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('ec-pop-up-select',{ref:"excludeRules",attrs:{"field":_vm.fields['exclude-rules'],"item-text":"name","item-value":"id","initial-items":_vm.excludeRulesInit,"dialog-text":"Add/Remove Rules for Exclusion","data-cy":"select-excludedRules"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selected = ref.selected;
var items = ref.items;
var close = ref.close;
var selectAll = ref.selectAll;
return [_c('card',{attrs:{"title":"Exclude Rules"}},[_c('template',{slot:"toolbar-actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('rule-lookup',{attrs:{"value":items},on:{"toggle-select-all":function($event){return selectAll( $event.items, $event.value )},"item-selected":function($event){return selected( $event.item, $event.value )}}})],2)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-label',[_vm._v("Metadata Filter")]),_vm._l((_vm.metadata),function(datum,index){return _c('div',{key:index},[(index)?_c('div',{staticClass:"d-flex justify-center"},[_vm._v("OR")]):_vm._e(),_c('metadata-editor',{attrs:{"label":index + 1 + ':',"edit-tooltip":'Edit Filter',"edit-mode":datum.editMode},on:{"update:editMode":function($event){return _vm.$set(datum, "editMode", $event)},"update:edit-mode":function($event){return _vm.$set(datum, "editMode", $event)}},model:{value:(datum.value),callback:function ($$v) {_vm.$set(datum, "value", $$v)},expression:"datum.value"}},[_c('template',{slot:"actions"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","elevation":"0","color":"warning lighten-1"},on:{"click":function($event){return _vm.deleteMetadata(index)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Filter")])])],1)],2)],1)}),(_vm.metadata.length)?_c('div',{staticClass:"d-flex justify-center"},[_vm._v("OR")]):_vm._e(),_c('metadata-editor',{attrs:{"label":_vm.metadata.length + 1 + ':',"edit-tooltip":'Edit Filter',"edit-mode":_vm.newMetadataEditMode},on:{"update:editMode":function($event){_vm.newMetadataEditMode=$event},"update:edit-mode":function($event){_vm.newMetadataEditMode=$event}},model:{value:(_vm.newMetadata),callback:function ($$v) {_vm.newMetadata=$$v},expression:"newMetadata"}})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }