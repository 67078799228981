/* eslint-disable max-len */
import { parse as queryParse } from '@/parser/QueryExpressionParser';
import { parse as templateParse } from '@/parser/TemplateExpressionParser';
import { Field } from '@/models/form';

const requiredMessage = '{_field_} is required';
const minimumMessage = (value: number) => `{_field_} must be at least ${value}`;
const maximumMessage = (value: number) => `{_field_} must be at most ${value}`;
const minLengthMessage = (value: number) => `{_field_} must be at least ${value} charaters long`;
const maxLengthMessage = (value: number) => `{_field_} must not be longer than ${value} charaters long`;
const uriMessage = '{_field_} must be a valid uri format';
const protocolsMessage = (value: string[]) => `{_field_} must use one of these protocols - ${value.flat ? value.flat() : 'http/https'}`;
const duplicatesMessage = (other: Field) => `{_field_} must not contain a duplicate of '${other.label}'`;

function checkValidUrl(value: string): boolean {
  if (!value) {
    return true;
  }
  try {
    // eslint-disable-next-line no-new
    new URL(value);
    return true;
  } catch {
    return false;
  }
}

function checkUriProtocols(value: string, protocols: string[]): boolean {
  if (!value) {
    return true;
  }
  try {
    const enteredProtocol = new URL(value).protocol;
    return protocols.indexOf(enteredProtocol.replace(':', '')) >= 0;
  } catch {
    return false;
  }
}

function isValidQuery(value: string): boolean {
  if (!value) {
    return true;
  }
  try {
    queryParse(value);
    return true;
  } catch {
    return false;
  }
}

function isValidTemplate(value: string): boolean {
  if (!value) {
    return true;
  }
  try {
    templateParse(value);
    return true;
  } catch {
    return false;
  }
}

function notContainDuplicates(value: any[], other: Field) {
  const valueIsArray = value instanceof Array;
  const otherIsArray = other.value instanceof Array;
  if (!valueIsArray || !otherIsArray) return true;

  const otherValue = other.value as any[];

  const duplicates = value.filter((x) => otherValue.includes(x));

  return duplicates.length === 0;
}

function s(value: any) {
  return value === null || value === undefined ? '' : `${value}`;
}

export const required = () => (value: any) => Boolean(value) || requiredMessage;
export const min = (minimum: number) => (value: any) => +value >= minimum || minimumMessage(minimum);
export const max = (maximum: number) => (value: any) => +value <= maximum || maximumMessage(maximum);
export const minLength = (length: number) => (value: any) => s(value).length >= length || minLengthMessage(length);
export const maxLength = (length: number) => (value: any) => s(value).length <= length || maxLengthMessage(length);
export const queryExpression = (value: any, queryExpressionErrorMessage: string) => isValidQuery(s(value)) || queryExpressionErrorMessage;
export const templateExpression = (value: any, templateExpressionErrorMessage: string) => isValidTemplate(s(value)) || templateExpressionErrorMessage;
export const uri = () => (value: any) => checkValidUrl(s(value)) || uriMessage;
export const uriProtocols = (protocols: string[]) => (value: any) => checkUriProtocols(s(value), protocols) || protocolsMessage(protocols);
export const noDuplicates = (other: () => Field) => (value: any) => notContainDuplicates(value, other()) || duplicatesMessage(other());

/* eslint-enable max-len */
