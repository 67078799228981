















































import {
  Component, Vue, Inject, Watch,
} from 'vue-property-decorator';
import RuleTable from '@/components/library-maintenance/rule-table.vue';
import { Library, Rule } from '@/models/library-maintenance.d';
import LibraryService from '@/services/library-service';
import RuleService from '@/services/rule-service';

@Component({
  components: {
    RuleTable,
  },
})
export default class RuleLookup extends Vue {
  @Inject() LibraryService!: LibraryService;

  @Inject() RuleService!: RuleService;

  libraryId = '';

  libraries: Library[] = [];

  librariesLoading = false;

  loading = false;

  rules = {
    page: 1,
    size: 20,
    sortBy: ['name'],
    sortDesc: [false],
    filter: '',
    items: [] as Rule[],
    loading: false,
    totalItems: 0,
  }

  async mounted() {
    await this.loadLibraries();
  }

  async loadLibraries() {
    this.librariesLoading = true;

    try {
      const list = await this.LibraryService.listLibraries({
        size: 100,
        page: 1,
        sort: ['name,asc'],
      });

      this.libraries = list._embedded.libraries;
    } finally {
      this.librariesLoading = false;
    }
  }

  get listData() {
    return [
      this.libraryId,
      this.rules.page,
      this.rules.size,
      this.rules.sortBy,
      this.rules.sortDesc,
      this.rules.filter,
    ];
  }

  @Watch('listData', { deep: true })
  async loadRules() {
    if (!this.libraryId) return;

    this.rules.loading = true;

    const sort = this.rules.sortBy.map(
      (by, index) => `${by},${this.rules.sortDesc[index] ? 'desc' : 'asc'}`,
    );

    const pagination = {
      page: this.rules.page,
      size: this.rules.size,
      sort,
    };

    try {
      const list = await this.RuleService.listRules(this.libraryId, pagination, this.rules.filter);

      this.rules.page = list.page.number;
      this.rules.size = list.page.size;
      this.rules.totalItems = list.page.totalElements;
      this.rules.items = list._embedded.rules;
    } finally {
      this.rules.loading = false;
    }
  }
}
