





















































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

import { TableHeader } from "@/components/layout/models/table.d";
import { TableOptions } from "@/models/form";
import EnabledOnly from "@/components/library-maintenance/enabled-only.vue";

import config from "@/config";

@Component({
  components: {
    EnabledOnly
  }
})
export default class RulesTable extends Vue {
  @Prop({ default: () => ({}) }) options!: TableOptions;

  @Prop() libraryId!: string;

  @Prop({ default: false }) apiFault!: boolean;

  @Prop({ default: 0 }) serverItemsLength!: number;

  @PropSync("itemsPerPage", { default: () => config.defaultPageSize }) syncedItemsPerPage!: number;

  @PropSync("page", { default: 1 }) syncedPage!: number;

  @PropSync("filter", { default: "" }) syncedFilter!: string;

  @PropSync("enabledOnly", { default: "" }) syncedEnabledOnly!: string;

  headers: TableHeader[] = [];

  get numberOfPages() {
    if (this.syncedItemsPerPage === 0) return 1;
    return Math.max(1, Math.ceil(this.serverItemsLength / this.syncedItemsPerPage));
  }

  hasHandler(name: string): boolean {
    return name in this.$listeners;
  }

  mounted() {
    this.headers = [
      {
        sortable: true,
        sortDirection: "",
        text: "Rule Name",
        value: "name"
      },
      {
        sortable: true,
        sortDirection: "",
        text: "Description",
        value: "description"
      },
      {
        sortable: false,
        text: "Metadata",
        value: "metadata"
      },
      {
        sortable: false,
        sortDirection: "",
        text: "Enabled",
        value: "enabled"
      }
    ];

    if (this.hasHandler("item:delete")) {
      this.headers.push({
        sortable: false,
        sortDirection: "",
        text: "Delete",
        value: "actions"
      });
    }
  }
}
