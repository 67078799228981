/* eslint-disable max-classes-per-file */
export interface Region {
  Accept(visitor: TemplateVisitor): void;
}

export class TemplateModel {
  constructor(public regions: Region[]) { }

  Accept(visitor: TemplateVisitor) {
    this.regions.forEach(region => region.Accept(visitor));
  }
}

export class TemplateText implements Region {
  constructor(public text: string) { }

  Accept(visitor: TemplateVisitor): void {
    visitor.VisitText(this.text);
  }
}

export class TemplateParameter implements Region {
  constructor(public name: string, public quoted: boolean) { }

  Accept(visitor: TemplateVisitor): void {
    visitor.VisitParameter(this.name, this.quoted);
  }
}

export class TemplateFunction implements Region {
  constructor(public name: string, public args: string[]) { }

  Accept(visitor: TemplateVisitor): void {
    visitor.VisitFunction(this.name, this.args);
  }
}

export class TemplateCondition implements Region {
  constructor(public clause: string, public regions: Region[]) { }

  Accept(visitor: TemplateVisitor): void {
    visitor.VisitCondition(this.clause, this.regions);
  }
}

export interface TemplateVisitor {
  VisitText(text: string): void;
  VisitParameter(name: string, quoted: boolean): void;
  VisitFunction(name: string, args: string[]): void;
  VisitCondition(clause: string, regions: Region[]): void;
}
