




















import {
  Component, Vue, Inject, Watch,
} from 'vue-property-decorator';
import LibraryTable from '@/components/library-maintenance/library-table.vue';
import { Library } from '@/models/library-maintenance.d';
import LibraryService from '@/services/library-service';

@Component({
  components: {
    LibraryTable,
  },
})
export default class LibraryLookup extends Vue {
  @Inject() LibraryService!: LibraryService;

  libraries = {
    page: 1,
    size: 20,
    sortBy: ['name'],
    sortDesc: [false],
    filter: '',
    items: [] as Library[],
    loading: false,
    totalItems: 0,
  }

  async mounted() {
    await this.loadLibraries();
  }

  get listData() {
    return [
      this.libraries.page,
      this.libraries.size,
      this.libraries.sortBy,
      this.libraries.sortDesc,
      this.libraries.filter,
    ];
  }

  @Watch('listData', { deep: true })
  async loadLibraries() {
    this.libraries.loading = true;

    const sort = this.libraries.sortBy.map(
      (by, index) => `${by},${this.libraries.sortDesc[index] ? 'desc' : 'asc'}`,
    );

    const pagination = {
      page: this.libraries.page,
      size: this.libraries.size,
      sort,
    };

    try {
      const list = await this.LibraryService.listLibraries(pagination, this.libraries.filter);

      this.libraries.page = list.page.number;
      this.libraries.size = list.page.size;
      this.libraries.totalItems = list.page.totalElements;
      this.libraries.items = list._embedded.libraries;
    } finally {
      this.libraries.loading = false;
    }
  }
}
