var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',{attrs:{"title":_vm.policyId ? 'Edit Policy' : 'Create Policy',"toolBarBackgroundColor":(!_vm.policyId || _vm.policy.enabled) ?
      'primary' : 'disabled'}},[_c('v-progress-linear',{attrs:{"indeterminate":"","height":"2","active":_vm.loading}}),_c('template',{slot:"toolbar-actions"},[(_vm.policyId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{staticClass:"history-link",attrs:{"to":{
          name: 'policy-history',
          params: {
            policyId: _vm.policyId
          }
        }}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({},on),[_vm._v("mdi-history")])],1)],1)]}}],null,false,2977985299)},[_c('span',[_vm._v("History")])]):_vm._e(),(_vm.policyId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.setEnabledState(!_vm.policy.enabled)}}},on),[(_vm.policy.enabled)?_c('v-icon',[_vm._v("mdi-checkbox-marked")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])],1)]}}],null,false,438668576)},[(_vm.policy.enabled)?_c('span',[_vm._v("Disable")]):_c('span',[_vm._v("Enable")])]):_vm._e(),(_vm.policyId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deletePolicy()}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,3581954116)},[_c('span',[_vm._v("Delete")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.back()}}},on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Back")])])],1),_c('template',{slot:"actions"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.back()}}},[_vm._v(" Back ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.editor.reset()}}},[_vm._v(" Reset ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.editor.fetch(_vm.save, _vm.formValidationError)}}},[_vm._v(" Save ")])],1),_c('policy-editor',{ref:"editor",attrs:{"api-errors":_vm.apiErrors},model:{value:(_vm.policy),callback:function ($$v) {_vm.policy=$$v},expression:"policy"}})],2),_c('ec-snack-bar',{attrs:{"options":_vm.snackbarOptions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }