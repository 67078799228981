
























import Vue from "vue";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { Field } from "@/models/form";

@Component
export default class HdPopUpSelect extends Vue {
  @Prop({ default: () => new Field([], "") }) field!: Field;

  @Prop({ default: "Open Dialog" }) dialogText!: string;

  @Ref() select!: any;

  @Prop() initialItems!: any[];

  @Prop() disabled!: boolean;

  @Prop({ default: 1600 }) dialogWidth!: number;

  dialog = false;

  items: any[] = [];

  @Watch("initialItems", { immediate: true })
  onInitialItemsChanging(): void {
    if (this.initialItems !== undefined) {
      for (let i = 0; i < this.initialItems.length; i += 1) {
        this.items.push(this.initialItems[i]);
      }
    }
  }

  openDialog(): void {
    if (!this.disabled) this.dialog = true;
  }

  closeDialog(): void {
    this.dialog = false;
  }

  selected(value: any, selected: boolean): void {
    const index = this.items.findIndex(
      (item) => this.select.getValue(item) === this.select.getValue(value)
    );

    if (selected && index < 0) {
      this.items.push(value);
    } else if (!selected && index >= 0) {
      this.items.splice(index, 1);
    }

    this.field.value = this.items.map((item) =>
      this.select.returnObject ? item : this.select.getValue(item)
    );
  }

  reset(): void {
    this.items = [];
    if (this.initialItems !== undefined) {
      for (let i = 0; i < this.initialItems.length; i += 1) {
        this.items.push(this.initialItems[i]);
      }
    }
  }

  selectAll(values: any[], all: boolean): void {
    values.forEach((value: any) => {
      this.selected(value, all);
    });
  }
}
