/* eslint-disable max-classes-per-file */
/* eslint-disable no-useless-constructor */

export class Expression {}
export class Modifier {}

export class Query {
  constructor(public expression: Expression) {}
}

export class Path {
  children: Path[];
  constructor(node: string)
  constructor(node: string, child: Path)
  constructor(node: string, children: Path[])
  constructor(public node: string, children?: Path[] | Path) {
    if (!children) {
      this.children = [];
    }
    else if (Array.isArray(children)) {
      this.children = children;
    }
    else {
      this.children = [children];
    }
  }
}

export class Field {
  constructor(public path: Path) {}
}

export class Comparison extends Expression {
  constructor(public field: Field[], public expression: Expression) {
    super();
  }
}
export class Nested extends Expression {
  constructor(public field: Field[], public expression: Expression) {
    super();
  }
}
export class Exists extends Expression {
  constructor(public field: Field[]) {
    super();
  }
}

export class Term extends Expression {
  text: string;

  modifiers: Modifier[];

  op: string;

  isRegex: boolean;

  constructor(text: string, modifiers: Modifier[], op: string, isRegex: boolean) {
    super();

    this.text = text;
    this.isRegex = isRegex;
    this.modifiers = modifiers;
    this.op = op;
  }
}

export class And extends Expression {
  constructor(public left: Expression, public right: Expression) {
    super();
  }
}
export class Or extends Expression {
  constructor(public left: Expression, public right: Expression) {
    super();
  }
}
export class Xor extends Expression {
  constructor(public left: Expression, public right: Expression) {
    super();
  }
}
export class Not extends Expression {
  constructor(public expression: Expression) {
    super();
  }
}

export class Near extends Expression {
  constructor(public distance: number, public left: Term, public right: Term) {
    super();
  }
}

export class FuzzyModifier extends Modifier {
  constructor(public similarity: number | null) {
    super();
  }
}
export class ProximityModifier extends Modifier {
  constructor(public distance: number | null) {
    super();
  }
}

export class IgnoreModifier extends Modifier { }
export class OrderModifier extends Modifier { }
