






































































































import {
  Component, Inject, Vue, Watch,
} from 'vue-property-decorator';

import PolicyService from '@/services/policy-service';
import PolicyEditor from '@/components/library-maintenance/policy-editor.vue';
import Card from '@/components/material/Card.vue';
import { SnackbarOptions } from '@/models/form';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';
import { Policy, PolicyUpdateModel } from '@/models/library-maintenance.d';
import { backOr } from '@/router';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';
import { ApiError } from '../models/hal.d';

const appState = getModule(AppState);

@Component({
  components: {
    PolicyEditor,
    Card,
    EcSnackBar,
  },
})
export default class PolicyView extends Vue {
  @Inject() PolicyService!: PolicyService

  policyId?: string;

  policy: Policy = {} as Policy;

  apiErrors: ApiError[] = [];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  loading = false;

  @Watch('$route', { immediate: true })
  onRouteChanged(value: any): Promise<void> {
    this.policyId = value.params.id;

    if (this.policyId === undefined) return Promise.resolve();
    this.loading = true;

    return this.PolicyService
      .readSingle(this.policyId)
      .then((policy) => {
        this.policy = policy;
        this.loading = false;
      });
  }

  @Watch('policy')
  onPolicyUpdated() {
    appState.setTitle(`Policy: ${this.policy?.name}`);
  }

  back() {
    backOr({ name: 'policies' });
  }

  save(model: PolicyUpdateModel) {
    if (!this.policyId) {
      this.snackbarOptions = EcSnackBar.makeProgressOptions('Creating policy ...');
      this.PolicyService.createPolicy(model)
        .then((policy) => {
          this.snackbarOptions.value = false;
          this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully created'); });

          this.$router.replace({ name: 'policy', params: { id: policy.id } });
        })
        .catch((http) => {
          this.snackbarOptions = EcSnackBar.makeDefaultOptions();
          this.apiErrors = http.response?.data?.errors || [];
        });

      return;
    }

    this.snackbarOptions = EcSnackBar.makeProgressOptions('Saving policy ...');
    this.PolicyService.updatePolicy(this.policyId, model)
      .then((policy) => {
        this.snackbarOptions.value = false;
        this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully updated'); });

        this.policy = policy;
      })
      .catch((http) => {
        this.snackbarOptions = EcSnackBar.makeDefaultOptions();
        this.apiErrors = http.response?.data?.errors || [];
      });
  }

  formValidationError() {
    this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Please correct any errors before saving');
  }

  async setEnabledState(enable: boolean) {
    if (!this.policyId) return;

    try {
      const text = enable
        ? 'If you enable this policy, it will be processed on subsequent bookings.'
        : 'If you disable this policy, it will not be processed on subsequent bookings.';

      appState.openDialog({
        title: enable ? 'Enable Policy?' : 'Disable Policy?',
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [{
          name: enable ? 'Enable' : 'Disable',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Changing policy ...');
            this.PolicyService.setEnabledState(this.policyId, enable)
              .then(() => {
                this.policy.enabled = enable;

                this.snackbarOptions.value = false;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Changed policy'); });
              })
              .catch((error: Error) => {
                this.snackbarOptions.value = false;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to change policy: ${error.message}`); });
              })
          },
        }, {
          name: 'Cancel',
          color: 'primary',
          handler: () => Promise.resolve(false),
        }],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Failed to change policy'); });
    }
  }

  async deletePolicy() {
    if (!this.policyId) return;

    try {
      appState.openDialog({
        title: 'Delete Policy?',
        text: '<p>Are you sure?</p><p>If you delete this policy, this policy will no longer be run on subsequent bookings.</p>',
        actions: [{
          name: 'Delete',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Deleting policy ...');
            this.PolicyService.deletePolicy(this.policyId)
              .then(() => {
                  this.snackbarOptions.value = false;
                  this.back();
                }
              )
              .catch((error: Error) => {
                  this.snackbarOptions.value = false;
                  this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete policy: ${error.message}`); });
                }
              )
            }
          },
          {
            name: 'Cancel',
            color: 'primary',
            handler: () => Promise.resolve(false),
          }
        ]
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete policy: ${error}`); });
    }
  }
}
